const cartesianist = {
  NETHER_ROWS: 4,
  ROWS: 20,
  COLUMNS: 10,

  LOOP_SPEED: 800,
  SCORE_PER_LEVEL: 45,

  KEY_SPEED_X: 150,
  KEY_SPEED_Y: 45,

  CLEAR_LINE_DELAY: 500,

  COLORS: {
    lavender: "#E6E6FA",
    peach: "#FFDAB9",
    mint: "#98FB98",
    skyBlue: "#87CEEB",
    lilac: "#C8A2C8",
    babyPink: "#F4C2C2",
    paleYellow: "#FFFF99",
    lightGreen: "#90EE90",
    babyBlue: "#AEC6CF"
  }
}

export default {
  cartesianist,
}