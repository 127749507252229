import { getRandomInt, getRandomColor } from "@/util/util";
import cloneDeep from 'lodash.clonedeep';

const clone = cloneDeep;

const shapes = {
  long: {
    type: 'long',
    positions: [{ x: 0, y: 0 }, { x: 1, y: 0 }, { x: 2, y: 0 }, { x: 3, y: 0 }, { x: 4, y: 0 }],
    pivot: { x: 2, y: 0 }
  },
  square: {
    type: 'square',
    positions: [{ x: 0, y: 0 }, { x: 1, y: 0 }, { x: 0, y: 1 }, { x: 1, y: 1 }],
  },
  tShape: {
    type: 'tShape',
    positions: [{ x: 0, y: 0 }, { x: 1, y: 0 }, { x: 2, y: 0 }, { x: 1, y: 1 }, { x: 1, y: 2 }],
    pivot: { x: 1, y: 0 }
  },
  batonShape: {
    type: 'batonShape',
    positions: [{ x: 0, y: 0 }, { x: 0, y: 1 }, { x: 0, y: 2 }, { x: 1, y: 2 }, { x: 0, y: 3 }],
    pivot: { x: 0, y: 2 }
  },
  reverseBatonShape: {
    type: 'reverseBatonShape',
    positions: [{ x: 1, y: 0 }, { x: 1, y: 1 }, { x: 1, y: 2 }, { x: 0, y: 2 }, { x: 1, y: 3 }],
    pivot: { x: 1, y: 2 }
  },
  cornerShape: {
    type: 'cornerShape',
    positions: [{ x: 0, y: 0 }, { x: 1, y: 0 }, { x: 0, y: 1 }],
    pivot: { x: 0, y: 0 }
  },
  pairShape: {
    type: 'pairShape',
    positions: [{ x: 0, y: 0 }, { x: 0, y: 1 }],
    pivot: { x: 0, y: 0 }
  },
};

export const getRandomShape = () => {
  const shapeTypes = Object.keys(shapes);
  const randomShapeType = shapeTypes[getRandomInt(0, shapeTypes.length - 1)];
  const shape = new Shape(randomShapeType);
  shape.color = getRandomColor();
  return shape;
}

export const getShape = ({ type, color, positions }) => {
  const shape = new Shape(type);
  shape.color = color;
  shape.positions = positions;
  return shape;
}

export class Shape {
  static count = 0;

  static getRandomShape = getRandomShape;
  static getShape = getShape;

  constructor(type) {
    if (type && !Object.prototype.hasOwnProperty.call(shapes, type)) {
      new Error(`Bogus shape: ${type}`)
    }

    const tempShape = shapes[type];

    this.type = tempShape.type;
    this.positions = clone(tempShape.positions);
    this.pivot = clone(tempShape.pivot);

    this.color = '';
    this.label = ''; // for debug

    this.id = ++Shape.count;
  }

  get left() {
    return this.positions.reduce((acc, { x }) => Math.min(acc, x), 999);
  }

  get right() {
    return this.positions.reduce((acc, { x }) => Math.max(acc, x), 0);
  }

  get top() {
    return this.positions.reduce((acc, { y }) => Math.min(acc, y), 999);
  }

  get bottom() {
    return this.positions.reduce((acc, { y }) => Math.max(acc, y), 0);
  }

  get width() {
    return this.right - this.left + 1;
  }

  get height() {
    return this.bottom - this.top + 1;
  }

  moveX(num) {
    if (this.pivot) {
      this.pivot.x += num;
    }

    this.positions.forEach((position) => {
      position.x += num;
    });

    return clone(this.positions);
  }

  moveY(num) {
    if (this.pivot) {
      this.pivot.y += num;
    }

    this.positions.forEach((position) => {
      position.y += num;
    });

    return clone(this.positions);
  }

  /* ------ ROTATION ------ */

  rotate(times) {
    if (times === 0 || times > 3 || !this.pivot) {
      return clone(this.positions);
    }

    this.positions.forEach((position) => {
      let x, y;

      if (times > 0) { // clockwise
        x = this.pivot.x + this.pivot.y - position.y;
        y = this.pivot.y - this.pivot.x + position.x;
      }
      else { // counter-clockwise
        x = this.pivot.x - this.pivot.y + position.y;
        y = this.pivot.y + this.pivot.x - position.x;
      }

      position.x = x;
      position.y = y;
    });

    return this.rotate((Math.abs(times) - 1) * Math.sign(times));
  }

  clone() {
    return clone(this);
  }
}
