<template>
  <div class="chat-view">
    <ContactModal
      v-if="modal.contact.open"
      @close="handleModalClose"
      class="contact-modal"
    />
    <div class="left-view">
      <h3>for keeps</h3>
      <nav class="static-menu">
        <ul>
          <li><a href="https://onehundredpercentagile.dev/blog/BossLogic" target="_blank">About Us</a></li>
          <li><a tabindex="0" style="cursor: pointer;" @click="modal.contact.open = true">Contact</a></li>
          <li><a href="https://onehundredpercentagile.dev/blog" target="_blank">Blog</a></li>
        </ul>
      </nav>
      <h3>for fun</h3>
      <nav class="static-menu">
        <ul>
          <li><a href="https://onehundredpercentagile.dev/blog/GrooveBox" target="_blank">Groove Box</a></li>
          <li><router-link to="thecartesianist">The Cartesianist</router-link></li>
          <li><router-link to="quizroom">Quiz Room</router-link></li>
        </ul>
      </nav>
    </div>
    <div @complete="typedComplete" class="center">
      <div
        class="content"
        ref="contentEl"
      >
        <template v-for="(item, index) in chatItems.filter(item => !item.hidden)" :key="index">
          <MyChat
            v-if="item.type === 'mychat'"

            class="chat-item"
            :text="item.text"
            :user-name="item.userName"
          />
          <SystemChat
            v-else-if="item.type === 'textList'"

            class="chat-item"
            :texts="item.texts"
            :user-name="item.userName"

            @typing="typing"
            @complete="complete"
          />
          <SystemMenu
            v-else-if="item.type === 'menu'"

            class="chat-item"
            :menuOptions="item.menuOptions"

            @complete="complete"
            @select="submitChat($event, { role: 'user' })"
            @to="router.push({ name: $event })"
          />
        </template>
        <span
          class="wait-cursor"
          v-if="loading.thinking"
        >
          ▌
        </span>
      </div>
      <form
        autocomplete="off"
        @submit.prevent="submitChat(textInput, { role: 'user' })"
      >
        <ChatInput
          ref="inputEl"
          v-model="textInput"
          :placeholder="loading.processing ? 'Loading..' : 'Type into the A.I.'"
          :loading="loading.processing"
        />
      </form>
    </div>
    <div class="right-view"></div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  nextTick,
  onMounted,
  onActivated,
} from 'vue';

import MyChat from './MyChat.vue';
import SystemChat from './SystemChat.vue';
import SystemMenu from './SystemMenu.vue';
import ChatInput from '@/components/ChatInput.vue';
import ContactModal from './ContactModal.vue';

import { minSpin } from '@/util/util';

import { useRouter } from 'vue-router';
const router = useRouter();

/* ------ DATA | COMPUTED ------ */

const chatItems = ref([]);
const chatBuffer = ref([]);
const textInput = ref('');

const loading = reactive({
  processing: false, // for button
  thinking: false, // for wait cursor
})

const modal = reactive({
  contact: {
    open: false,
  }
})

const handleModalClose = () => {
  modal.contact.open = false;
}

const contentEl = ref(null);
const inputEl = ref(null);

/* ------ METHODS ------ */

const postChat = (message, history) => fetch(`/api/chat`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    message,
    history
  }),
});

const mapChat = (data) => {
  let obj;

  switch(data.type) {
    case 'textList': {
      obj = {
        type: data.type,
        userName: data.userName,
        texts: data.texts,
        role: data.role,
      }
      break;
    }
    case 'menu': {
      obj = {
        type: data.type,
        userName: data.userName,
        menuOptions: data.menuOptions,
      }
      break;
    }
    default:
      throw 'Invalid chat object';
  }

  return obj;
};

function mapHistoryForOpenAI() {
  let history = chatItems.value
    .filter(item => item.role)
    .map(item => ({
      role: item.role,
      content: item.texts?.join(' ') || item.text,
    }))
    .slice(-10); // open ai costs money

  return history;
}

const submitChat = async (message, { role, hidden = false }) => {
  if (['user', 'assistant'].includes(role) === false) {
    throw new Error('Invalid role');
  }

  // inputEl.value.focus();
  if (window.matchMedia('(min-width: 768px)').matches) {
    focusInput();
  }

  if (loading.processing || !message) return;

  chatItems.value.push({
    type: 'mychat',
    text: message,
    userName: 'Internet Wanderer',
    hidden,
    role,
  });

  textInput.value = '';

  try {
    loading.processing = true;
    loading.thinking = true;

    await nextTick(() => scrollDown());
    const history = mapHistoryForOpenAI();
    const response = await minSpin(() => postChat(message, history), 500);
    loading.thinking = false;

    const { data } = await response.json();

    if (data.chatArray.length) {
      chatItems.value.push(mapChat(data.chatArray.shift()));
    }

    // if there's still data after the above shift
    if (data.chatArray.length) {
      chatBuffer.value = data.chatArray.map(mapChat);
    }
  }
  catch(err) {
    console.error('ai error', err);
    loading.processing = false;
  }
};

const clear = () => {
  chatItems.value = [];
  chatBuffer.value = [];
  textInput.value = '';
  loading.processing = false;
  loading.thinking = false;
}

const initialize = () => {
  clear();
  submitChat('Introduce yourself.', { role: 'user', hidden: true });
}

/* ------ CURSOR & SCROLL ------ */

const typing = () => {
  scrollDown();
}

const complete = () => {
  if (chatBuffer.value.length) {
    chatItems.value.push(chatBuffer.value.shift());
  }
  else {
    loading.processing = false;
  }
  scrollDown();
}

let scrollTimer = Date.now();

const scrollDown = () => {
  if (!contentEl.value) return;

  const scrolledFromBottom =
    contentEl.value.scrollHeight - contentEl.value.scrollTop - contentEl.value.clientHeight;

  if (scrollTimer > 50 && scrolledFromBottom < 250) {
    contentEl.value.scrollTo({ top: contentEl.value.scrollHeight });
  }
  scrollTimer = Date.now();
}

const focusInput = () => {
  const inputElement = inputEl.value.getInputRef()
  if (inputElement) {
    inputElement.focus();
  }
}

/* ------ LIFECYCLE ------ */

onMounted(() => {
  initialize();
});

onActivated(() => {
  if (chatItems.value.length) {
    contentEl.value.scrollTo({ top: contentEl.value.scrollHeight });
  }
});
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro&display=swap');

/* ------ MAIN ------ */
.chat-view {
  display: flex;
  gap: 10px;

  @media (min-width: $tablet) {
    gap: 20px;
  }

  height: 100%;

  border-top: 1px solid var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  padding: 10px;

  font-size: 18px;
  color: white;
  font-family: Anonymous Pro, monospace;
  line-height: 22px;

  .left-view, .right-view {
    display: flex;
    flex-direction: column;
    align-items: center;



    @media (min-width: $tablet) {
      flex: 1 1 0;
    }

    h3 {
      display: none;
      margin: 0;
      font-size: 14px;
      color: var(--primary-color);
      position: relative;
      top: 10px;
      font-style: italic;

      @media (min-width: $tablet) {
        display: block;
      }
    }

    .static-menu {
      display: none;
      font-size: 16px;
      line-height: 20px;
      position: relative;

      @media (min-width: $tablet) {
        display: flex;
        min-width: 200px;
        padding: 12px 20px;
        margin: 12px 0;

        background-color: var(--primary-very-dim);
        justify-content: center;
      }

      text-align: center;
      position: relative;

      border: 1px dotted var(--primary-color);
      border-radius: 5px;
      text-shadow: 2px 2px black;

      ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;

        li {

          a {
            color: var(--primary-color);
            text-decoration: none;
            font-family: Anonymous Pro, monospace;
            transition: color 0.1s ease-in-out;

            &:hover, &:focus {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }


  // .right {
  //   display: none;
  //   @media (min-width: $tablet) {
  //     display: flex;
  //     flex: 1 1 0;
  //   }
  // }


  .center {
    height: 100%;
    @media (min-width: $tablet) {
      max-width: 800px;
    }
    margin: 0 auto;

    display: flex;
    flex-direction: column;

    .content {
      overflow-y: auto;
      flex: 1 0 0;
      padding-bottom: 20px;

      @media (min-width: $tablet) {
        padding-right: 45px;
        padding-left: 45px;
        padding-bottom: 80px;
      }

      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .wait-cursor {
        animation: fadeInOut 0.4s infinite;
      }
    }
  }
}

/* ------ CHAT ------ */

.chat-item {
  margin-bottom: 30px;
  background-color: rgb(0,0,0,0.3);
  border-radius: 5px;
}
</style>
