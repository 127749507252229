// import './scss/global.scss';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueGtag from 'vue-gtag';

const notChrome = false;

console.green = (...args) => console.log('\x1b[32m%s\x1b[0m', ...args)
console.blue = (...args) => console.log('\x1b[34m%s\x1b[0m', ...args)
console.yellow = (...args) => console.log('\x1b[33m%s\x1b[0m', ...args)
console.red = (...args) => console.log('\x1b[31m%s\x1b[0m', ...args)
console.orange = (...args) => console.log('\x1b[33m%s\x1b[0m', ...args)
console.orangeBold = (...args) => console.log('\x1b[33m\x1b[1m%s\x1b[0m', ...args)

if (notChrome) {
  console.green = console.log;
  console.blue = console.log;
  console.orange = console.log;
  console.orangeBold = console.log;
  console.red = console.log;
}

const gtId = 'G-Q9WK9J62WV';
// const gtId = 'G-4ZLBLEKHZF'; // http://127.0.0.1:8080

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VueGtag, {
    config: { id: gtId }
  }, router)

app.mount('#app')
