<template>
  <div
    class="chat-reply"
  >
    <p class="user"><span>{{ userName }}</span></p>
    <p v-for="(local, index) in localTexts" :key="index">
      <span v-html="local.displayText"></span><span v-if="local.showCursor" class="cursor"> ▌</span>
    </p>
  </div>
</template>

<script setup>

const config = {
  variability: 0,
  speed: 15,
  atOnce: 3,
}

import { onMounted, ref, defineEmits, defineProps, onBeforeMount } from 'vue';
import { getRandomWeightedInt } from '@/util/util';

const props = defineProps({
  userName: {
    type: String,
  },
  texts: Array,
  typing: {
    type: Boolean,
    default: true,
  }
});

const localTexts = ref([]);

const emit = defineEmits(['typing', 'complete']);

const msCalc = () => {
  const { speed, variability } = config;
  const sign = Math.random() > .5 ? 1 : -1;
  return Math.abs(speed + sign * (variability * Math.random()));
}

const humanTyping = async (text) => {
  const index = localTexts.value.push({ displayText: '', showCursor: true }) - 1;

  const characters = text.trim().replace(/[\n\t\r\u00A0\u200B]/g, '').split('');

  const recurse = (characters) => {
    return new Promise((resolve, reject) => {
      if (characters.length === 0) {
        localTexts.value[index].showCursor = false;
        resolve();
      }

      setTimeout(() => {
        let c = '';

        // add atOnce number of characters, weighted low
        const num = getRandomWeightedInt(1, config.atOnce, 0.3);

        for (var i = 0; i < num; i++) {
          if(characters.length) {
            c += characters.shift();
          }
        }

        localTexts.value[index].displayText += c;

        recurse(characters).then(resolve).catch(reject)
      }, msCalc())
    })
  }

  await recurse(characters);
}

onMounted(async () => {
  for (const text of props.texts) {
    emit('typing')
    await humanTyping(text);
    emit('typing')
  }
  emit('complete')
});
</script>

<style lang="scss">
.chat-reply {
  .user {
    margin-bottom: -8px;
    width: fit-content;

    span{
      font-weight: bold;
      width: fit-content;
      background-image: linear-gradient(to left, #ff00ff, var(--primary-color));
      -webkit-background-clip: text; /* Clip the gradient to the text */
      background-clip: text;
      color: transparent !important;
    }
  }
  .cursor {
    animation: fadeInOut 1s infinite;
  }

  ul {
    li {
      margin-bottom: 5px;
    }
  }
}
</style>