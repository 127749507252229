<template>
<div
  class="cell-grid"
  @mouseleave="mouseLeave"
  @mouseup="click"
  :style="{
    'grid-template-columns': `repeat(${columns}, 1fr)`,
    'grid-template-rows': `repeat(${rows}, 1fr)`,
  }"
>
  <div
    v-for="(cell, index) in grid"
    @mouseenter="mouseEnter(index)"
    :data-id="cell.id || ''"
    class="cell"
    :class="{
      'active-row': selectable && activeRow === Math.floor(index / columns),
      'deleting': cell.deleting,
    }"
    :style="{
      backgroundColor: colors[cell.color] || 'transparent',
    }"
    :key="index"
  >
    <span
      v-if="cell.mid"
      class="marquee"
    >{{ cell.marqueeCharacter }}</span>
    <span v-else-if="cell.label || coords">{{ cell.label || `${cell.x}${cell.y},`}}</span>
  </div>
</div>
</template>

<script setup>
import { ref, defineProps, watch, defineEmits } from 'vue';
import constants from '../../util/constants';

const emit = defineEmits(['row-selected']);

const props = defineProps({
  grid: {
    type: Array,
    required: true,
  },
  columns: [Number, String],
  rows: [Number, String],
  selectable: Boolean,
  coords: {
    type: Boolean,
    default: false,
  },
})

const colors = ref(constants.cartesianist.COLORS);
const activeRow = ref();

function mouseEnter(index) {
  activeRow.value = Math.floor(index / props.columns)
}

function mouseLeave() {
  activeRow.value = null;
}

function click() {
  if (props.selectable) {
    emit('row-selected', activeRow.value);
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap');

.cell-grid {
  display: grid;
  border-bottom: 1px dotted var(--white-dim);
  border-left: 1px dotted var(--white-dim);
  border-right: 1px dotted var(--white-dim);

  gap: 1px;
  opacity: 1;
}

.cell {
  width: 100%;
  min-width: 24px;

  @media (max-width: 500px) {
    min-width: 22px;
  }

  aspect-ratio: 1/1;
  font-size: 12px;
  text-shadow: 1px 1px 1px black;
  position: relative;

  &.active-row {
    cursor: pointer;
  }

  &.deleting {
    // background-color: red !important;
    animation: clear 0.52s linear;
  }

  @keyframes clear {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .marquee {
    font-family: 'Major Mono Display', monospace;
    font-size: 16px;
    text-shadow: 0 0 20px white;
  }
}
</style>