<template>
  <div
    @click.self="emitClose"
    class="modal"
    :class="{
      'clear-overlay': clearOverlay
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from 'vue';

export default {
  name: 'BasicModal',
  props: {
    title: {
      type: String,
      default: ''
    },
    clearOverlay: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['close'],
  setup(props, { emit }) {
    const emitClose = () => {
      emit('close');
    };

    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        emit('close');
      }
    };

    onMounted(() => {
      window.addEventListener('keyup', handleEscape);
    });

    onUnmounted(() => {
      window.removeEventListener('keyup', handleEscape);
    });

    return {
      emitClose,
    };
  }
};
</script>

<style lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &.clear-overlay {
    background-color: transparent;
  }
}
</style>
