<template>
  <modern-header
    @icon-click="iconClick"
    :display-close-icon="menu.open"
  />

  <mobile-menu
    :open="menu.open"
  />

  <router-view v-slot="{ Component }">
    <keep-alive include="BizView">
      <component :is="Component" :open="menu.open" />
    </keep-alive>
  </router-view>
</template>

<script setup>
import { onMounted, watch, onBeforeUnmount, reactive } from 'vue';
import { useRoute } from 'vue-router';

import ModernHeader from '@/components/ModernHeader.vue';
import MobileMenu from '@/components/MobileMenu.vue';

const route = useRoute();

const menu = reactive({ open: false });

const iconClick = () => {
  menu.open = !menu.open;
};

const updateBodyClass = (routeName) => {
  document.body.className = routeName;
};

watch(() => route.name, (newRouteName) => {
  updateBodyClass(newRouteName);
});

onMounted(() => {
});

onBeforeUnmount(() => {
  document.body.className = '';
});


onMounted(() => {
  updateBodyClass(route.name);
  let style = document.createElement('style');
  document.head.appendChild(style);

  setTimeout(() => {
    style.sheet.insertRule('body, #app { transition: margin 0.5s ease-in-out; }', 0);
  }, 250);
})
</script>

<style lang="scss">
:root {
  padding: 0;
  margin: 20px 0;

  body, #app {
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    height: calc(100svh - 42px);

    @media(min-width: $tablet) {
      margin: 0 30px;

      .mobile-menu {
        display: none;
      }
    }

    @media(max-width: 450px) {
      margin: 0 10px;
    }

    &.thecartesianist {
      height: calc(100svh - 20px) !important;
      overflow: hidden;
    }
  }
}

</style>
