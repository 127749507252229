<template>
  <header class="modern-header">
    <h1 class="pointer" @click="router.push({ name: 'biz' })">BossLogic Software</h1>
    <a href="https://tppdigital.com/" class="tpp-digital" target="_blank">TPP Digital</a>

    <div class="toggle" @click="iconClick">
      <img v-show="!displayCloseIcon" src="/hamburger.svg" alt="menu" />
      <img v-show="displayCloseIcon" src="/hamburger_x.svg" alt="close" />
    </div>
  </header>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { defineProps, defineEmits } from 'vue';

const router = useRouter();
const props = defineProps({
  title: {
    type: String,
    default: 'BossLogic Software',
  },
  displayCloseIcon: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['icon-click']);

const iconClick = () => emit('icon-click');
</script>

<style lang="scss">
.modern-header {
  display: flex;
  justify-content: space-between;

  font-family: 'Righteous', sans-serif;
  padding-left: 10px;
  padding-top: 2px;
  position: relative;
  border-radius: 5px;
  background-image: linear-gradient(to right, #ff00ff, var(--primary-color));
  margin: 0 0 15px 0;

  box-shadow: 0 0 10px lightblue;

  text-shadow:
    -1.3px -1.3px 0 var(--primary-color),
    -1.3px 1.3px 0 var(--primary-color),
    1.3px -1.3px 0 var(--primary-color),
    1.3px 1.3px 0 var(--primary-color);

  h1 {
    color: black;
    font-weight: bold;
    margin: 0;
    text-align: left;
    font-size: 32px;
  }

  /* Target Firefox using a CSS hack */
  @-moz-document url-prefix() {
    /* Your Firefox-specific CSS rules here */
    h1 {
      margin-top: 4px;
    }
  }

  .tpp-digital {
    position: absolute;
    bottom: -2px;
    right: 8px;
    font-size: 14px;
    font-family: monospace;
    transition: bottom 0.3s ease-in-out;
  }

  .toggle {
    display: none;
    filter: drop-shadow(1px 1px 0 black) drop-shadow(0 0 1px black);
    position: relative;
    right: 6px;
    top: 1px;
    z-index: 1001;

    cursor: pointer;

    img {
      width: 30px;
      height: 30px;
    }
  }

  @media(max-width: $tablet) {
    .tpp-digital {
      color: var(--primary-color);
      text-shadow: none;
      text-shadow: 0 0 3px white;
      bottom: -16px;
    }

    .toggle {
      display: block;
    }
  }
  @media(max-width: 415px) {
    h1 {
      font-size: 26px;
      padding-right: 15px;
      position: relative;
      top: 1px;
    }

    .tpp-digital {
      font-size: 12px;
    }
  }

  @media(max-width: 360px) {
    h1 {
      font-size: 22px;
      top: 3px;
    }
  }
}
</style>
