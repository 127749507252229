import constants from './constants';

export function getRandomInt(min, max) {
  // Use Math.floor() to round down to the nearest whole number
  min = Math.ceil(min);
  max = Math.floor(max);

  // Generate a random number between min and max (inclusive)
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// lower lambda biases towards lower integer
export function getRandomWeightedInt(min, max, lambda) {
  if (min === max) {
    return min; // If min and max are the same, return min
  }

  // Calculate the exponential random number
  const random = -Math.log(Math.random()) / lambda;

  // Map the exponential random number to the desired range
  const range = max - min + 1;
  const weightedRandom = Math.floor(random * range) + min;

  // Ensure the result is within the valid range
  return Math.min(Math.max(weightedRandom, min), max);
}

export const minSpin = async (apiCall, minDelay) => {
  const loadStartTime = Date.now();
  const response = await apiCall();
  const loadEndTime = Date.now();

  const loadTime = loadEndTime - loadStartTime;

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(response);
    }, minDelay - loadTime);
  });
}

export const getRandomColor = () => {
  const colorsArray = Object.keys(constants.cartesianist.COLORS);
  return colorsArray[getRandomInt(0, colorsArray.length - 1)];
}

export function findContiguousGroups(coords) {
  let visited = new Set();
  let result = [];

  function isVisited(x, y) {
    return visited.has(`${x},${y}`);
  }

  function visit(x, y) {
    visited.add(`${x},${y}`);
  }

  function getAdjacentCoords(x, y) {
    return [[x - 1, y], [x + 1, y], [x, y - 1], [x, y + 1]];
  }

  function dfs(x, y, group) {
    if (isVisited(x, y)) return;
    visit(x, y);
    group.push({ x, y });

    getAdjacentCoords(x, y).forEach(([nx, ny]) => {
      if (coords.some(coord => coord.x === nx && coord.y === ny)) {
        dfs(nx, ny, group);
      }
    });
  }

  coords.forEach(coord => {
    if (!isVisited(coord.x, coord.y)) {
      let group = [];
      dfs(coord.x, coord.y, group);
      result.push(group);
    }
  });

  return result;
}

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
