  <template>
  <div
    class="the-cartesianist-view"
    :class="{ 'lighting-effect': lightingEffect }"
  >
    <div class="left tablet-left desktop-left">
      <h1 style="margin-top: 30px;">The Cartesianist<span class="version">1.0</span></h1>
      <div class="key-col tablet-to-row game-loop-keys">
        <div
          title="Pause, Resume (␣)"
        >
          <span
            @click="mobilePlayPause"
          >
            <img v-if="!game.initialized || game.paused" class="img-icon" src="/play.svg" alt="Start" />
            <img v-else-if="game.initialized && game.loopInterval" class="img-icon" src="/pause.svg" alt="Pause" />
          </span>
        </div>
        <div
          @click="game.initialized ? endGame() : false;"
          title="Restart (R)"
          :class="{
            'disabled': !game.initialized,
          }"
        >
          <img class="img-icon" src="/stop.svg" alt="End" />
        </div>
      </div>
      <div class="key-col tablet-to-row game-loop-keys tablet-gameloop-keys">
        <div
          class="key pressable"
          title="Pause, Resume (␣)"
        >
          <span
            v-if="!game.initialized"
            @click="startGame"
          >
            <span class="game-loop-text">Start</span>
          </span>
          <span
            v-else-if="game.initialized && game.loopInterval"
            @click="pauseGame"
          >
            <span class="game-loop-text">Pause</span>
          </span>

          <span
            v-else-if="game.paused"
            @click="resumeGame"
          >
            <span class="game-loop-text">Resume</span>
          </span>
        </div>
        <div
          @click="game.initialized ? endGame() : false;"
          title="Restart (R)"
          class="key pressable"
          :class="{
            'disabled': !game.initialized,
          }"
        >
          <span>{{ game.initialized ? 'End' : '-' }}</span>
        </div>
      </div>
      <img class="main-image" src="/cartesianist4.webp" alt="The Cartesianist" />
      <div class="nav-keys">
        <div class="key-col tablet-to-row">
          <div class="key" title="Left (←)"><span>←</span></div>
          <div class="key" title="Drop (↓)"><span>↓</span></div>
          <div class="key" title="Right (→)"><span>→</span></div>
        </div>
        <div class="key-col tablet-to-row">
          <div class="key" title="Rotate Left (z)"><span>z</span></div>
          <div class="key" title="Rotate Right (x)"><span>x</span></div>
        </div>
      </div>
    </div>
    <div
      class="center tablet-center"
      :class="{
        'paused': game.paused ,
        'game-over': game.over,
      }">
      <div class="paused-message">
        PAUSED
      </div>
      <div class="game-over-message">
        <span>GAME OVER</span>
        <span> {{ gameOverMessage() }} </span>
        <span>
          SCORE: {{ gameScore }}<br>
          LEVEL: {{ game.level }}<br>
          LINES: {{ game.lines }}
        </span>
        <span v-if="game.newPersonalTop">New Personal Top!</span>
        <span v-if="game.newPersonalTop"> {{ game.topScore }} </span>
      </div>

      <div class="grids-wrap">
        <CellGrid
          class="nether-grid"
          :class="{
            'near-death': game.nearDeath
          }"

          :grid="netherGrid"
          :columns="constants.cartesianist.COLUMNS"
          :rows="constants.cartesianist.NETHER_ROWS"
          @row-selected="eraser($event)"
          :selectable="game.erasers > 0"
        />
        <CellGrid
          :grid="grid"
          :columns="constants.cartesianist.COLUMNS"
          :rows="dynamicRows"
          @row-selected="eraser($event)"
          :selectable="game.erasers > 0"
        />
      </div>
    </div>
    <div class="right tablet-right desktop-right">
      <div class="score">
        <h2>Score<br> {{ gameScore }}</h2>
        <h2>Level<br> {{ game.level }}</h2>
        <h2>Lines<br> {{ game.lines }}</h2>
        <h2 class="min">Sc<br> {{ gameScore }}</h2>
        <h2 class="min">Lv<br> {{ game.level }}</h2>
        <h2 class="min">Ln<br> {{ game.lines }}</h2>
      </div>
      <div class="erasers">
        <span>Eraser</span>
        <span class="min">ER</span>
        <span class="min"
          style="margin-bottom: 0.83em;"
        > {{ game.erasers.length || 0 }}</span>
        <div v-if="game.erasers" class="eraser not-min">
          <span v-for="(_, index) in Array(game.erasers).fill(0)" :key="index">─</span>
        </div>
        <div class="eraser-empty not-min" v-else>(empty)</div>
      </div>
      <div v-if="game.topScore" class="score top-score">
        <header>Top Score</header>
        <header class="min">Tp</header>
        <span>{{ game.topScore }}</span>
      </div>
    </div>
  </div>
  </template>

<script setup>
import {
  onMounted,
  onUnmounted,
  onActivated,
  onDeactivated,
  ref,
  computed,
  reactive,
} from 'vue';

import { getRandomInt, findContiguousGroups } from "@/util/util";
import constants from '../../util/constants';

import { Shape } from './shapes';
import CellGrid from './CellGrid.vue';

/* ------ DATA ------ */

const activeShape = ref({});
const shapes = reactive({});
const lightingEffect = ref(false);

const dynamicRows = ref(14);

const marquee = reactive({
  count: 1,
  dict: {},
  durationInterval: null,
  loopInterval: null,
});

const KB = reactive({
  downInterval: null,
  rightInterval: null,
  leftInterval: null,
  shapeId: null,
  lockSpaceBar: false,
});

const game = reactive({
  lines: 0,
  level: 0,
  shapes: 0,

  paused: false,
  deletingRows: false,

  erasers: 4, // blast a row (limited number)

  loopSpeed: constants.cartesianist.LOOP_SPEED,
  loopInterval: null,

  initialized: false,
  nearDeath: false, // close to game over

  over: false,

  topScore: 0,
  newPersonalTop: false,
});

const touch = reactive({
  lastElement: null,
  lastX: null,
  lastY: null,
  active: false,
  id: null,
});

/* ------ COMPUTED ------ */

const gameScore = computed(() => {
  return game.lines * 10 + game.shapes;
});

const netherGrid = computed(() => {
  const m = marquee.dict; // refactor: find alternative to forcing recompute

  const numberOfCells = constants.cartesianist.COLUMNS * constants.cartesianist.NETHER_ROWS;
  return Array
    .from(
      { length: numberOfCells },
      (_, index) => {
        const x = index % constants.cartesianist.COLUMNS;
        const y = -(constants.cartesianist.NETHER_ROWS - Math.floor(index / constants.cartesianist.COLUMNS));

        const shape = findShapeUnderCoordinate({ x, y });
        const marquee = findMarqueeUnderCoordinate({ x, y }) // bug: grid doesn't recompute when marquee changes

        let id, color, label, deleting;

        if (shape) {
          color = shape.color;
          label = shape.label;
          id = shape.id;
          deleting = shape.positions.find((coord) => coord.x === x && coord.y === y)?.deleting;
        }

        let mid, marqueeLabel, marqueeCharacter;

        if (marquee) {
          mid = marquee.mid;
          marqueeLabel = marquee.marqueeLabel;
          marqueeCharacter = marquee.positions.find((coord) => coord.x === x && coord.y === y)?.marqueeCharacter;
        }

        return {
          x,
          y,
          color,
          deleting,
          id,
          label,
          mid, // marquee id
          marqueeLabel,
          marqueeCharacter
        };
      })
});

const grid = computed(() => {
  const numberOfCells = constants.cartesianist.COLUMNS * dynamicRows.value;

  return Array
    .from(
      { length: numberOfCells },
      (_, index) => {
        const x = index % constants.cartesianist.COLUMNS;
        const y = Math.floor(index / constants.cartesianist.COLUMNS);
        const shape = findShapeUnderCoordinate({ x, y });

        let id, color, label, deleting;

        if (shape) {
          color = shape.color;
          label = shape.label;
          id = shape.id;
          deleting = !!shape.positions.find((coord) => coord.x === x && coord.y === y)?.deleting;
        }

        // final cell object
        return {
          x,
          y,
          id,
          deleting,
          color,
          label,
        };
      });
});

/* ------ METHODS ------ */

function invokeLightingEffect() {
  lightingEffect.value = true;
  setTimeout(() => lightingEffect.value = false, 1500);
}

function gameOverMessage() {
  if (gameScore.value < 100) {
    return 'The Cartesianist is displeased. :(';
  }
  if (gameScore.value < 200) {
    return 'Decent job! Keep at it!';
  }
  return 'WOW!! Nice work!';
}

let lastLoopTime = Date.now();

async function gameLoop(loopType = 'first', speed) {
  // const delayBetween = Date.now() - lastLoopTime;
  lastLoopTime = Date.now();
  removeOrphanShapes();

  if (loopType === 'clear') {
    clearInterval(game.loopInterval);
    game.loopInterval = undefined;
  }

  if (loopType === 'first') {
    activeShape.value = insertShape();
    return game.loopInterval = setTimeout(() => gameLoop('recurse start', game.loopSpeed), game.loopSpeed);
  }

  // looptype === 'recurse'
  if (/^recurse/.test(loopType) && game.loopInterval) {
    let completedLines = [];
    if (advanceAllShapes() === 0 && !game.deletingRows) {
      completedLines = findCompletedRows();

      if (completedLines.length) {
        game.deletingRows = true;
        await fadeAndDeleteRows(completedLines)
        game.deletingRows = false;

        for (let i = 0; i < completedLines.length; i++) {
          advanceAllShapes();
        }
      }

      activeShape.value = insertShape();
    }

    if (activeShape.value.top < -4) {
      activeShape.value.label = 'X';
      endGame();
    } else {
      updateLevel();

      const speed = completedLines.length
        ? 0
        : game.loopSpeed;

      game.loopInterval = setTimeout(() => gameLoop('recurse', speed), speed);
    }
  }

  if (/^resume/.test(loopType) && !game.loopInterval) {
    return game.loopInterval = setTimeout(() => gameLoop('recurse resume', 200), 200);
  }
}

function insertShape() {
  const shape = Shape.getRandomShape();

  // random horizontal position
  shape.moveX(getRandomInt(0, constants.cartesianist.COLUMNS - shape.width));

  // random rotate
  shape.rotate(getRandomInt(0, 3));

  // correct for x bounds
  if (shape.left < 0) {
    shape.moveX(-shape.left);
  }
  else if (shape.right > constants.cartesianist.COLUMNS - 1) {
    shape.moveX(constants.cartesianist.COLUMNS - 1 - shape.right);
  }

  // try putting shape.top at y == 0
  if (
    shape.top != 0
    && noCollision(shape.positions, shape.id)
    && noCollision(shape.clone().moveY(-shape.top), shape.id)
  ) {
    shape.moveY(-shape.top);
  }

  // otherwise insert as-is or scooch up
  else {
    while (noCollision(shape.positions, shape.id) === false) {
      shape.moveY(-1);
    }
  }

  game.shapes++;
  shapes[shape.id] = shape;
  return shape;
}

function removeOrphanShapes() {
  Object.values(shapes).forEach((shape) => {
    if (shape.positions.length === 0) {
      delete shapes[shape.id];
    }
  });
}

// For 2.0: refactor to allow many messages simulataneously
function messageMarquee({ text, ypos, speed, duration }) {
  // clear to prevent collision with existing marquee
  clearInterval(marquee.durationInterval);
  clearInterval(marquee.loopInterval);
  delete marquee.dict[marquee.count];
  marquee.count++;

  // begin

  const _marq = {
    mid: marquee.count,
    speed,
    ypos,
    positions: [], // { x, y, marqueeCharacter }
  };

  for (let i = 0; i < text.length; i++) {
    _marq.positions.push({
      x: (i - text.length),
      y: ypos,
      marqueeCharacter: text[i],
    });
  }

  marquee.dict[_marq.mid] = _marq;

  marquee.loopInterval = setInterval(() => {
    marquee.dict[_marq.mid].positions.forEach((position) => {
      position.x++;
    });

    if (marquee.dict[_marq.mid].positions[0].x === (constants.cartesianist.COLUMNS - text.length)) {
      clearInterval(marquee.loopInterval);

      marquee.durationInterval = setTimeout(() => {
        delete marquee.dict[_marq.mid];
      }, duration)
    }
  }, _marq.speed);
}

function findMarqueeUnderCoordinate({ x, y }) {
  return Object.values(marquee.dict).find((marquee) => {
    return marquee.positions.some(coord => coord.x === x && coord.y === y);
  });
}

// deletes line and splits shapes into new ones
const fadeRow = (y) => {
  Object.values(shapes).forEach((shape) => {
    shape.positions
      .filter((position) => position.y === y)
      .forEach((position) => {
        position.deleting = true;
      });
  })
}

const deleteRow = (y) => {
  Object.values(shapes).forEach((shape) => {
    shape.positions = shape.positions.filter((position) => position.y !== y);

    // split shapes, form new ones
    if (shape.positions.length) {
      const positionGroups = findContiguousGroups(shape.positions)

      if (positionGroups.length > 1) {
        shape.positions = positionGroups.shift();

        positionGroups.forEach((group) => {
          const newShape = Shape.getShape({
            type: shape.type,
            color: shape.color,
            positions: group
          });

          shapes[newShape.id] = newShape;
        });
      }
    }
  });
}

// clear rows, update / split / destroy shapes
function findCompletedRows() {
  const lines = [];

  for (let y = 0; y < dynamicRows.value; y++) {
    const line = grid.value.filter((cell) => cell.y === y);

    if (line.every((cell) => cell.id)) {
      lines.push(line);
    }
  }

  return lines;
}

async function fadeAndDeleteRows(completedLines) {
  completedLines.forEach((line) => {
    fadeRow(line[0].y);
  });

  await new Promise((resolve) => setTimeout(resolve, constants.cartesianist.CLEAR_LINE_DELAY));

  completedLines.forEach((line) => {
    deleteRow(line[0].y);
    game.lines++;
  });
}

function eraser(y) { // blast a row
  deleteRow(y);
  game.erasers--;
}

function updateLevel() {
  const old = game.level;
  game.level = Math.floor(gameScore.value / constants.cartesianist.SCORE_PER_LEVEL);
  game.loopSpeed = constants.cartesianist.LOOP_SPEED - (game.level * 100);

  if (old !== game.level) {
    messageMarquee({
      text: `LEVEL ${game.level}!`,
      ypos: -1,
      speed: 30,
      duration: 5000,
    });

    invokeLightingEffect();

    game.erasers++;
  }
}

// @returns {number} cell changes. 0 suggests its time to insert a shape
function advanceAllShapes() {
  let changeCount = 0;

  Object.values(shapes).forEach((shape) => {
    if (noCollision(shape.clone().moveY(1), shape.id)) {
      changeCount++;

      if (KB.downInterval && shape.id === KB.shapeLockId) return; // exclude activeShape w/ held S key

      return shape.moveY(1);
    }
  });

  // check pieces in the nether grid (player may lose the game)
  game.nearDeath = Object.values(shapes).some(
    s => s.positions.some(p => p.y < 0) && s !== activeShape.value
  );

  return changeCount;
}

function findShapeUnderCoordinate({ x, y }, id) {
  return Object.values(shapes).find((shape) => {
    if (!shape.positions) {
      return false;
    }

    if (id && shape.id === id) {
      return false;
    }

    return shape.positions?.some((position) => position.x === x && position.y === y);
  });
}

function noCollision(positions, id) {
  const collided = positions.some((position) => {
    if (position.x < 0) {
      return true;
    }
    if (position.x >= constants.cartesianist.COLUMNS) {
      return true;
    }
    if (position.y >= dynamicRows.value) {
      return true;
    }

    const found = findShapeUnderCoordinate(position, id);

    if (found) {
      return true;
    }
  });

  return !collided;
}

/* ------ LISTENERS ------ */

const validKeys = [
  // pause, resume
  '', ' ', 'Space', 'Spacebar',

  // navigate
  'ArrowLeft', 'ArrowRight', 'ArrowDown',

  // rotate
  'z', 'Z', 'x', 'X',

  // util
  'i', 'I', // insert shape
  'c', 'C', // clear console
  'r', 'R', // restart
  'e', 'E', // endGame
]

function mobilePlayPause() {
  if (!game.initialized) {
    return startGame();
  }
  else if (game.initialized && game.loopInterval) {
    return pauseGame();
  }
  else if (game.paused) {
    return resumeGame();
  }
}

function startGame() {
  invokeLightingEffect();

  messageMarquee({
    text: 'Begin!',
    ypos: -1,
    speed: 30,
    duration: 5000,
  });

  gameLoop('clear');

  game.nearDeath = false;
  game.initialized = true;
  game.over = false;
  game.level = 0;
  game.lines = 0;
  game.shapes = 0;
  game.paused = false;
  game.newPersonalTop = false;

  Object.keys(shapes).forEach(key => {
      delete shapes[key];
  });

  gameLoop();
}

function resumeGame() {
  game.paused = false;
  gameLoop('resume');
}

function pauseGame() {
  game.paused = true;
  gameLoop('clear');
}

function endGame() {
  gameLoop('clear');
  game.paused = false;
  game.over = true;
  game.initialized = false;
  activeShape.value = undefined;

  if (gameScore.value > game.topScore) {
    game.topScore = gameScore.value;
    game.newPersonalTop = true;
    localStorage.setItem('cartesianist-top-score', game.topScore);
  }

  return true;
}

function keyDownOther(event) {
  if (validKeys.includes(event.key) === false) {
    return;
  }

  switch (event.key) {
    case 'c':
    case 'C':
      return console.clear();

    // case 'i':
    // case 'I':
    //  return activeShape.value = insertShape();

    case 'r':
    case 'R':
      if (event.ctrlKey || event.metaKey) {
        return;
      }
      return startGame();
    case 'e':
    case 'E':
      if (game.initialized) {
        return endGame();
      }
      break;

    case '':
    case ' ':
    case 'Space':
    case 'SpaceBar':
      if (game.deletingRows) {
        return;
      }

      if (!game.initialized) {
        return startGame();
      }
      if (game.initialized && game.loopInterval) {
        return pauseGame();
      }
      if (game.initialized && !game.loopInterval) {
        return resumeGame();
      }
      break;

    default:
      break;
  }
}

function keyDownAction(event) {
  if (validKeys.includes(event.key) === false) {
    return;
  }

  if (activeShape.value?.id === undefined) {
    return;
  }

  if (game.loopInterval === undefined) {
    return;
  }

  switch (event.key) {
    case 'ArrowLeft':
    {
      if (KB.leftInterval) {
        break;
      }

      const movement = () => {
        if(noCollision(activeShape.value.clone().moveX(-1), activeShape.value.id)) {
          activeShape.value.moveX(-1);
        }
      }

      movement();
      KB.leftInterval = setInterval(() => {
        if (game.loopInterval) {
          return movement();
        }
        stopLeft({ key: 'a' });
      }, constants.cartesianist.KEY_SPEED_X);

      const stopLeft = (e) => {
        if (['ArrowLeft'].includes(e.key)) {
          clearInterval(KB.leftInterval);
          KB.leftInterval = null;
          document.removeEventListener('keyup', stopLeft);
        }
      };

      document.addEventListener('keyup', stopLeft);
      break;
    }
    case 'ArrowRight':
    {
      if (KB.rightInterval) {
        break;
      }

      const movement = () => {
        if(noCollision(activeShape.value.clone().moveX(1), activeShape.value.id)) {
          activeShape.value.moveX(1);
        }
      }

      movement();
      KB.rightInterval = setInterval(() => {
        if (game.loopInterval) {
          return movement();
        }
        stopRight({ key: 'ArrowRight' });
      }, constants.cartesianist.KEY_SPEED_X);

      const stopRight = (e) => {
        if (['ArrowRight'].includes(e.key)) {
          clearInterval(KB.rightInterval)
          KB.rightInterval = null;
          document.removeEventListener('keyup', stopRight);
        }
      };

      document.addEventListener('keyup', stopRight);
      break;
    }

    case 'z':
    case 'Z':
      if (noCollision(activeShape.value.clone().rotate(-1), activeShape.value.id)) {
        activeShape.value.rotate(-1);
      }
      break;
    case 'x':
    case 'X':
      if (noCollision(activeShape.value.clone().rotate(1), activeShape.value.id)) {
        activeShape.value.rotate(1);
      }
      break;

    case 'ArrowDown':
    {
      if (KB.downInterval || event.repeat) {
        break;
      }

      KB.shapeLockId = activeShape.value.id;
      let lastY = activeShape.value.top;

      KB.downInterval = setInterval(() => {
        if (!game.loopInterval || activeShape.value.top < lastY) {
          stopDown({ key: 'ArrowDown' });
        }
        else if (
          KB.downInterval
          && activeShape.value.top >= lastY
          && noCollision(activeShape.value.clone().moveY(1), activeShape.value.id)
        )
        {
          activeShape.value.moveY(1);
        }
        else if (KB.downInterval && activeShape.value.top >= lastY) {
          stopDown({ key: 'ArrowDown' });

          // bug: these are still getting found while fadeAndDelete hasn't finished deleting..
          const completedLines = findCompletedRows();

          if (completedLines.length && !game.deletingRows) {
            game.deletingRows = true;
            gameLoop('clear');
            fadeAndDeleteRows(completedLines)
              .then(() => {
                for (let i = 0; i < completedLines.length; i++) {
                  advanceAllShapes();
                }

                game.deletingRows = false;
                gameLoop('resume collide-fade');
              })
          }
          else if (!game.deletingRows) {
            gameLoop('clear');
            gameLoop('resume collide');
          }
        }
      }, constants.cartesianist.KEY_SPEED_Y);

      const stopDown = (e) => {
        if (['ArrowDown'].includes(e.key)) {
          clearInterval(KB.downInterval)
          KB.shapeLockId = null;
          KB.downInterval = null;
          document.removeEventListener('keyup', stopDown);
        }
      };

      document.addEventListener('keyup', stopDown);
      break;
    }

    default:
      break;
  }
}



function touchAction(e) {

  if(e.composedPath().some((el) => el.classList?.contains('cell'))) {
    e.preventDefault()
  }
  else {
    return;
  }

  e.preventDefault();

  if (!activeShape.value || Object.keys(activeShape.value).length === 0) {
    return;
  }

  let touchedElement = ['touchstart', 'touchmove'].includes(e.type)
    ? document.elementFromPoint(e.touches[0].clientX, e.touches[0].clientY)
    : null;

  switch(e.type) {
    case 'touchstart':
        touch.lastElement = touchedElement;
        touch.lastX = e.touches[0].clientX;
        touch.lastY = e.touches[0].clientY;
      break;
    case 'touchmove':
        if (touchedElement === touch.lastElement) {
          return;
        }

        if (e.touches[0].clientX + 20 < touch.lastX) {
          if(noCollision(activeShape.value.clone().moveX(-1), activeShape.value.id)) {
            activeShape.value.moveX(-1);
          }
        }
        else if (e.touches[0].clientX - 20 > touch.lastX) {
          if(noCollision(activeShape.value.clone().moveX(1), activeShape.value.id)) {
            activeShape.value.moveX(1);
          }
        }
        else if (
          e.touches[0].clientY - 20 > touch.lastY
          && noCollision(activeShape.value.clone().moveY(1), activeShape.value.id)
        )
        {

          activeShape.value.moveY(1);
        }
        else {
          return;
        }

        touch.lastElement = touchedElement;
        touch.lastX = e.touches[0].clientX;
        touch.lastY = e.touches[0].clientY;
      break;
    case 'touchend': {
      const detouchedElement = document.elementFromPoint(
        e.changedTouches[0].clientX,
        e.changedTouches[0].clientY
      );

      if (e.target === detouchedElement) {
        if (noCollision(activeShape.value?.clone().rotate(1), activeShape.value.id)) {
          activeShape.value.rotate(1);
        }
      }
    }

      break;
    case 'touchcancel':
      console.log('touchcancel');
      break;
    default:
      break;
  }
}

/* ------ LIFECYCLE ------ */

onMounted(async() => {
  console.log('cartesianist onMounted');
  document.addEventListener('keydown', keyDownAction);

  document.addEventListener('keydown', keyDownOther);

  document.addEventListener('touchstart', touchAction, { passive: false });
  document.addEventListener('touchmove', touchAction, { passive: false });
  document.addEventListener('touchend', touchAction, { passive: false });
  document.addEventListener('touchcancel', touchAction, { passive: false });

  game.topScore = localStorage.getItem('cartesianist-top-score') || 0;

  if (window.matchMedia('(max-width: 500px)').matches) {
    await new Promise((resolve) => {
      setTimeout(() => {
        dynamicRows.value = Math.floor((window.innerHeight - 270) / 22);
        resolve();
      }, 1000);
    });
  }
  else {
    dynamicRows.value = constants.cartesianist.ROWS;
  }

  startGame();
});

onUnmounted(() => {
  console.log('cartesianist onUnmounted')
  document.removeEventListener('keydown', keyDownAction);
  document.removeEventListener('keydown', keyDownOther);

  document.removeEventListener('touchstart', touchAction);
  document.removeEventListener('touchmove', touchAction);
  document.removeEventListener('touchend', touchAction);
  document.removeEventListener('touchcancel', touchAction);

  gameLoop('clear');
  game.paused = false;
  game.over = true;
  game.initialized = false;
  activeShape.value = undefined;
});

onActivated(() => {
  console.log('cartesianist onActivated');
});

onDeactivated(() => {
  console.log('cartesianist onDeactivated');
});
</script>

<style lang="scss">
@import '@/scss/_mixins.scss';
@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap');

.the-cartesianist-view.lighting-effect {
  animation: swell-text-shadow 1s ease-in-out, swell-brightness 1s linear;

  .center .grids-wrap {
    animation: swell-box-shadow 1s linear;
  }
}

.the-cartesianist-view {
  display: flex;
  justify-content: center;
  color: white;
  margin-top: 15px;
  font-family: 'Anonymous Pro', monospace;
  gap: 15px;

  @media (max-width: 500px) {
    gap: 8px;
  }

  /* ------ LAYOUT ------ */
  h1, h2, p {
    margin-top: 0;
    font-family: 'Major Mono Display', monospace;
    background-color: rgb(0,0,0,0.7);
  }

  p {
    display: flex;
    min-height: 50px;
    align-items: center;
    justify-content: center;
  }

  .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &.tablet-left {
      @media(min-width: $tablet) {
        flex: 1;
        text-align: center;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
      }
    }

    &.desktop-left {
      @media(min-width: 1024px) {
        // flex: 1;
        // text-align: center;
        // align-self: center;

        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // gap: 30px;
      }
    }
  }


  .center {
    flex: 0 0 auto;
    max-width: 400px;

    &.tablet-center {
      @media(min-width: $tablet) {
        // flex: 3;
      }
    }
  }


  .right {
    flex: 1;


    &.tablet-right {
      @media(min-width: $tablet) {
        text-align: center;
        margin-top: 30px;
      }
    }
  }


  /* ------ SPECIFICS ------ */
  .left {
    h1 {
      display: none;
    }

    img.main-image {
      display: none;
    }

    .game-loop-keys {
      .img-icon {
        width: 45px;

        @media(max-width: 500px) {
          width: 36px;
        }

        cursor: pointer;
        display: inline-block;
        margin-bottom: 2px;

        &:active {
          transform: scale(0.9);
        }
      }


      &.tablet-gameloop-keys {
        display: none;
      }
    }

    .nav-keys {
      display: none;
      // display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .key-col {
      display: flex;
      flex-direction: column;

      &.tablet-to-row {
        @media (min-width: $tablet) {
          flex-direction: row;
        }
      }

      .key {
        min-width: 25px;
        height: 25px;
        border: 1px solid rgb(185, 185, 185);
        color: rgb(239, 239, 239);
        border-radius: 5px;
        text-align: center;
        background-color: rgb(24, 24, 24);
        padding: 0 5px;

        display: flex;
        justify-content: center;
        align-items: center;

        &.pressable {
          cursor: pointer;
          width: 90px;

          span {
            display: inline-block;
            width: 100%;
            padding: 4px;
          }
        }

        &.disabled {
          pointer-events: none;
        }
      }
    }

    &.tablet-left {
      @media (min-width: $tablet) {
        h1 {
          display: block;
          font-family: 'Major Mono Display', monospace;
          margin: 0;
          text-align: center;
          font-size: 22px;

          .version {
            font-size: 12px;
          }
        }

        img.main-image {
          width: 160px;
          display: inline-block;
        }


        .game-loop-keys {
          display: none;

          &.tablet-gameloop-keys {
            display: flex;
          }
        }

        .nav-keys {
          display: flex;
          justify-content: center;
          gap: 10px;
        }

        .key-col {
          justify-content: center;
          gap: 10px;

          .key {
            min-width: 25px;
            height: 25px;
            border-radius: 5px;

            &.pressable {
              width: 90px;

              span {
                display: inline-block;
                width: 100%;
                padding: 4px;
              }
            }
          }
        } // end .key-col
      } // end @media
    } // end .tablet-left

    &.desktop-left {
      @media(min-width: 1024px) {
        h1 {
          font-size: 28px;
        }

        img.main-image {
          width: 222px;
        }


        .game-loop-keys {
          display: none;

          &.tablet-gameloop-keys {
            display: flex;
          }
        }

        .nav-keys {
          display: flex;
          justify-content: center;
          gap: 10px;
        }

        .key-col {
          justify-content: center;
          gap: 10px;

          .key {
            min-width: 25px;
            height: 25px;
            border-radius: 5px;

            &.pressable {
              width: 90px;

              span {
                display: inline-block;
                width: 100%;
                padding: 4px;
              }
            }
          }
        } // end .key-col
      }
    }
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    .paused-message, .game-over-message {
      z-index: 1;
      display: none;
      text-align: center;
    }

    &.paused {
      background-color: rgba(185, 240, 210, 0.187);

      .paused-message {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }


    &.game-over {

      @include scrolling-laser-vertical(rgb(124, 0, 182));

      &.good {
        @include scrolling-laser-vertical(rgb(124, 0, 182));
      }

      &.exceptional {
        @include scrolling-laser-vertical(rgb(124, 0, 182));
      }

      .game-over-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;

        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        min-width: 80%;

        span {
          background-color: rgba(0,0,0,0.9);
          padding: 6px 10px;
          border-radius: 5px 5px 0 0;
          box-shadow:
          0 -1px 2px white,
          0 -1px 5px rgb(174, 0, 255);
          animation: hue-rotate 5s linear infinite;
        }
      }
    }

    .nether-grid.near-death {

      animation: near-death 0.5s infinite alternate;

      @keyframes near-death {
        from {
          background-color: transparent
        }
        to {
          background-color: rgba(124, 0, 182, 0.451);
        }
      }
    }
  }

  .right {
    .top-scores {
      display: none;
    }

    .erasers {
      @media(min-width: 501px) {
        span.min { display: none; }
        span.not-min { display: inline; }
      }

      @media(max-width: 500px) {
        margin-bottom: 0.83e;
        span.min { display: inline; }
        span, .not-min { display: none; }
      }

      display: flex;
      flex-direction: column;
      font-size: 16px;


      .eraser-empty {
        font-size: 16px;
      }
    }

    .score  {
      @media(max-width: 500px) {
        h2 { display: none !important; }
        h2.min { display: inline-block !important; }
      }
      @media(min-width: 501px) {
        h2 { display: inline-block !important; }
        h2.min { display: none !important; }
      }

      display: flex;
      flex-direction: column;

      h2 {
        font-family: 'Major Mono Display', monospace;
        border-radius: 5px;
        font-size: 16px;
      }

      &.top-score {
        @media(max-width: 500px) {
          header { display: none !important; }
          header.min { display: inline-block !important; }
        }

        @media(min-width: 501px) {
          header { display: inline-block !important; }
          header.min { display: none !important; }
        }

        header {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 5px;
          font-style: italic;
        }

        span {
          font-size: 16px;
        }
      }
    }

    &.tablet-right {
      @media(min-width: $tablet) {
        .top-scores {
          background-color: rgb(0,0,0,0.7);

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin: 0 auto;

          max-width: 250px;
          margin-bottom: 20px;

          h3 {
            margin: 0 0 5px 0;
            padding-bottom: 2px;
            font-size: 14px;
            border-bottom: 1px solid white;
            text-align: left;
            width: 100%;
          }


          .player {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 5px;
            font-size: 16px;
          }
        }

        .erasers {
          display: flex;
          flex-direction: column;
          margin: 60px 0;
          font-size: 20px;

          .eraser-empty {
            font-size: 16px;
          }
        }

        .score  {
          display: flex;
          flex-direction: column;
          align-items: center;

          h2 {
            display: inline-block;
            font-family: 'Major Mono Display', monospace;
            padding: 5px;
            border-radius: 5px;
            font-size: 1.5em;
          }
        }
      }
    }

    &.desktop-right {
      @media(min-width: 1024px) {
        .top-scores {
          width: 60%;

          .player {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            font-size: 16px;
          }
        }

        .erasers {
          display: flex;
          flex-direction: column;
          margin: 60px 0;
          font-size: 20px;

          .eraser-empty {
            font-size: 16px;
          }
        }

        .score  {
          display: flex;
          flex-direction: column;
          align-items: center;

          h2 {
            display: inline-block;
            font-family: 'Major Mono Display', monospace;
            padding: 5px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
</style>