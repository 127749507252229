import { createRouter, createWebHistory } from 'vue-router'
import { nextTick } from 'vue';

import BizView from '../views/biz/BizView.vue'
import TheCartesianistView from '../views/cartesianist/TheCartesianistView.vue'

const routes = [
  {
    path: '/',
    name: 'biz',
    meta: {
      keepAlive: true,
    },
    component: BizView
  },
  {
    path: '/thecartesianist',
    name: 'thecartesianist',
    meta: {},
    component: TheCartesianistView
  },
  {
    path: '/quizroom',
    name: 'quizroom',
    meta: {},
    component: () => import(/* webpackChunkName: "quizroom" */ '../views/quizroom/QuizRoomView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
