<template>
  <div class="menu-items">
    <div
      v-for="(option, index) in menuOptions"
      tabindex="0"
      class="menu-item"
      :key="index"
      @click="onSelect(option)"
      @keydown.enter="onSelect(option)"
    >
      {{ option.text }}
    </div>
  </div>
  </template>

<script setup>
  import { defineProps, defineEmits, onMounted } from 'vue'

  const emit = defineEmits(['complete', 'select', 'to'])

  function onSelect ({ to , text }) {
    if (to) {
      return emit('to', to)
    }
    emit('select', text)
  }

  defineProps({
    menuOptions: {
      type: Array,
      required: true
    },
  })

  onMounted(() => {
    emit('complete')
  })
  </script>

  <style lang="scss">
  .menu-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
    padding: 30px 0;

    .menu-item {
      width: 98%;
      @media(min-width: 1024px) {
        width: 80%;
      }
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;

      color: var(--primary-color);
      text-shadow: 1px 1px 1px black;
      position: relative;
      animation: fadeIn 0.3s ease-in-out;
      outline: 1px solid var(--primary-color);
      background-color: var(--primary-dim);

      &:hover, &:focus {
        border-bottom: 2px solid var(--primary-dim);
        border-right: 2px solid var(--primary-dim);
        top: -2px;
        margin-bottom: -2px;

        // text-shadow: 0 0 5px white;
      }
    }
  }
  </style>