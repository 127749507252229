<template>
<div class="chat-input">
  <input
    ref="inputEl"
    type="text"
    :placeholder="placeholder"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  />
  <button
    type="submit"
    :class="{
      'key-down': modelValue,
      loading: loading,
    }"
  ></button>
</div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  props: {
    placeholder: String,
    loading: Boolean,
    modelValue: String,
  },
  emits: ['submit', 'update:modelValue'],

  setup() {
    const inputEl = ref(null);

    function getInputRef() {
      return inputEl.value;
    }

    return {
      getInputRef,
      inputEl,
    };
  },
}
</script>

<style lang="scss" scoped>
.chat-input {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
  position: relative;

  @media (min-width: $tablet) {
    padding: 0 30px;
  }

  .reset {
    position: absolute;
    top: 2px;
    left: 50%; transform: translateX(-50%);
    color: white;
    font-size: 16px;
    background-color: black;

    &:hover {
      cursor: pointer;
    }
  }

  input {
    width: 100%;
    padding: 10px 40px 10px 10px;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    background-color: black;
    color: white;
    font-family: Anonymous Pro, monospace;
    font-size: 18px;

    margin-top: 30px;
    margin-bottom: 10px;
  }

  button {
    all: unset;
    position: absolute;

    right: 5px;
    @media (min-width: $tablet) {
      right: 35px;
    }

    width: 30px;
    height: 30px;

    top: calc(50% + 10px);
    transform: translateY(-50%);

    border-radius: 0 2px 2px 0;
    background-color: var(--primary-dim);
    transition: background-color 0.1s linear border-radius 0.1s linear;

    cursor: pointer;

    &:active, &:hover, &.key-down {
      background-color: var(--primary-color);
    }

    &:focus {
      outline: 1px solid white;
    }

    &.loading {
      border-radius: 50%;
    }
  }
}
</style>