<template>
<div class="mobile-menu" :class="{ 'open': open }">
  <ContactModal
    v-if="modal.contact.open"
    @close="modal.contact.open = false;"
    clear-overlay
  />
  <template v-else>
    <div class="menu" @click="$emit('click')">
      <h2><a href="https://onehundredpercentagile.dev/blog/BossLogic" target="_blank">About Us</a></h2>
      <h2>
        <a style="cursor: pointer;" @click="modal.contact.open = true">
          Contact
        </a>
      </h2>
    </div>
  </template>
</div>
</template>

<script setup>
import { defineProps, reactive, watch } from 'vue';
import ContactModal from '@/views/biz/ContactModal.vue';

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});

watch(() => props.open, () => modal.contact.open = false);

const modal = reactive({
  contact: {
    open: false,
  }
})
</script>

<style lang="scss">
.mobile-menu {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  font-family: 'Anonymous Pro', monospace;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .85);
  z-index: 1000;

  color: var(--primary-color);

  transform: translateX(-100%);

  transition: transform 0.2s ease-in-out;
  text-align: center;

  h2 {

    &:hover {
      cursor: pointer;
    }
  }

  &.open {
    transform: translateX(0);
  }

  font-size: 32px;
  @media (max-width: 500px) {
    font-size: 24px;
  }

  @media (max-width: 350px) {
    font-size: 22px;
  }

  a {
    color: inherit;
  }

  .menu {
    position: relative;
    top: -30px;
  }
}
</style>